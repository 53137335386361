var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "box-shadow": "4px 4px 10px 0px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": "20px",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "gap": "20px",
      "cursor": _vm._hasOnClick ? 'pointer' : 'default'
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('c-box', {
    attrs: {
      "min-width": "50px"
    }
  }, [_c('c-image', {
    attrs: {
      "object-fit": "cover",
      "size": "50px",
      "src": _vm.icon,
      "alt": _vm.label
    }
  })], 1), _c('c-box', {
    attrs: {
      "flex": "1"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1), _vm._hasOnButtonClick ? _c('c-box', [_c('BaseButton', {
    attrs: {
      "h": "36px",
      "font-size": "16px",
      "font-weight": "400"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onButtonClick.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }