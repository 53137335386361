<template>
  <c-box flex-grow="1">
    <!--    <Portal to="breadcrumb">-->
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        { label: 'Manajemen Klien', href: { name: 'admin.clients' } },
        { label: 'Detail Klien', href: { name: 'admin.clients-detail', query: $route.query } },
        { label: 'Detail Program', isCurrent: true },
      ]"
    />
    <!--    </Portal>-->
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
    >
      <!--      <Portal to="breadcrumb">-->
      <!--        <BreadcrumbPath-->
      <!--          padding="20px 90px"-->
      <!--          :paths="[-->
      <!--            { label: 'Manajemen Klien', href: $router.resolve({ name: 'admin.clients' }).route.fullPath  },-->
      <!--            { label: 'Detail Klien', href: $router.resolve({ name: 'admin.clients-detail', query: $route.query }).route.fullPath },-->
      <!--            { label: 'Detail Program', isCurrent: true },-->
      <!--          ]"/>-->
      <!--      </Portal>-->
      <c-box
        display="flex"
        flex-direction="column"
        gap="30px"
        padding="0 40px"
      >
        <c-box
          display="flex"
          justify-content="space-between"
          align-items="center"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="28px"
          >
            Detail Program
          </BaseText>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            {{ program.name }}
          </BaseText>
        </c-box>
        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Data Pribadi
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />
          <c-box
            display="flex"
            gap="30px"
            pos="relative"
          >
            <c-box>
              <c-image
                border-radius="100% "
                object-fit="cover"
                size="150px"
                :src="getPhotoUser(user.photoUrl)"
                :alt="user.fullName"
              />
            </c-box>
            <c-box
              width="100%"
              flex="1"
            >
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
              >
                Nama
              </BaseText>
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                {{ user.fullName }}
              </BaseText>
              <BaseText
                margin-top="24px"
                size-mobile="14px-2"
                size-desktop="16px"
              >
                Program
              </BaseText>
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                {{ program.name }} ({{ program.programService }})
              </BaseText>
              <c-box
                v-if="!openSetEndDate"
              >
                <BaseText
                  margin-top="24px"
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  Join Date - End Date
                </BaseText>
                <c-flex align-items="center">
                  <BaseText
                    size-mobile="16px"
                    size-desktop="18px"
                  >
                    {{ formatDateV2(program.startAt) }} - {{ formatDateV2(program.endAt) }}
                  </BaseText>
                  <c-button
                    v-if="isSetEndDate"
                    variant="link"
                    d="flex"
                    align-items="center"
                    ml="20px"
                    color="primary.400"
                    @click="openSetEndDate = true"
                  >
                    <c-box mr="5px">
                      <inline-svg
                        :src="require('@/assets/icons/icon-edit.svg')"
                        height="18px"
                        width="18px"
                        fill="#008C81"
                      />
                    </c-box>
                    Edit
                  </c-button>
                </c-flex>
              </c-box>
              <c-flex
                v-else
                gap="1rem"
              >
                <c-box>
                  <BaseText
                    margin-top="24px"
                    size-mobile="14px-2"
                    size-desktop="16px"
                  >
                    Join Date
                  </BaseText>
                  <BaseText
                    size-mobile="16px"
                    size-desktop="18px"
                    mt="12px"
                  >
                    {{ formatDateV2(program.startAt) }}
                  </BaseText>
                </c-box>
                <c-box>
                  <BaseText
                    margin-top="24px"
                    size-mobile="14px-2"
                    size-desktop="16px"
                  >
                    End Date
                  </BaseText>
                  <c-flex gap="8px">
                    <v-date-picker
                      v-model="endDate"
                      :masks="masks"
                      :min-date="new Date(minEndDate)"
                      :model-config="modelConfig"
                      mode="date"
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <c-flex
                          w="100%"
                          pos="relative"
                          align-items="center"
                        >
                          <c-box
                            pos="absolute"
                            left="0px"
                            z-index="2"
                            w="3rem"
                          >
                            <inline-svg
                              :src="require('@/assets/icons/icon-date.svg')"
                              height="25"
                              width="100%"
                              fill="#008C81"
                            />
                          </c-box>
                          <c-input
                            :value="inputValue"
                            :h="['48px', '50px']"
                            w="230px"
                            border-type="solid"
                            border-width="1px"
                            border-color="#888"
                            border-radius="8px"
                            pl="3rem"
                            pr="0px"
                            font-family="Roboto"
                            :font-size="['14px', '18px']"
                            font-weight="500"
                            color="#008C81"
                            readonly
                            @focus="() => {
                              togglePopover()
                            }"
                          />
                        </c-flex>
                      </template>
                    </v-date-picker>
                    <c-button
                      variant="solid"
                      :h="['48px', '50px']"
                      :font-size="['14px', '18px']"
                      variant-color="primary"
                      border-radius="1.5rem"
                      px="1.5rem"
                      @click="onOpenConfirm"
                    >
                      Simpan
                    </c-button>
                  </c-flex>
                </c-box>
              </c-flex>
              <BaseText
                margin-top="24px"
                size-mobile="14px-2"
                size-desktop="16px"
              >
                Durasi
              </BaseText>
              <BaseText
                size-mobile="16px"
                size-desktop="18px"
              >
                {{ program.duration }} Hari
              </BaseText>
            </c-box>
            <c-box 
              pos="absolute"
              top="0px"
              right="0px"
            >
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
                color="#008C81"
              >
                Counter day: {{ program.counterDay }}
              </BaseText>
            </c-box>
          </c-box>
        </c-box>
        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Ahli Gizi yang Dipilih
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />

          <CardNutritionist
            v-if="nutritionist"
            :is-disabled-change-nutritionist="!isAbleToChangeNutri"
            :name="getFullnameAndTitle(nutritionist.fullName, nutritionist.education)"
            :photo="nutritionist.photoUrl"
            :service-hours="nutritionist.serviceHour"
            :ratings="nutritionist.rating"
            :educations="educationsParser(nutritionist.education)"
            :specializations="nutritionist.specialization"
            :remaining-quota="nutritionist.remainingQuota"
            @on-change-nutritionist="handleChangeNutritionist"
          />
        </c-box>

        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Diet Data
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />
          <c-box
            display="grid"
            grid-template-columns="repeat(2, minmax(0, 1fr))"
            grid-gap="30px"
          >
            <SimpleCard
              v-for="menu in LIST_MENU_DIET_DATA"
              :key="menu.label"
              :icon="menu.icon"
              :label="menu.label"
              :button-label="menu.buttonLabel"
              @on-button-click="menu.onButtonClick && routerPush(menu.onButtonClick)"
              @on-click="routerPush(menu.onClick)"
            />
          </c-box>
        </c-box>

        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Jadwal Rencana Makan
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />
          <c-box
            display="grid"
            grid-template-columns="repeat(2, minmax(0, 1fr))"
            grid-gap="30px"
          >
            <SimpleCard2
              v-for="(v, i) in meal_plan_schedule"
              :key="v.id + i"
              :title="v.title"
              :description="v.date"
              :status="v.status"
            />
          </c-box>
        </c-box>
        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Jadwal ADIME Notes & Indikator Progress and Tracker
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />
          <c-box
            display="grid"
            grid-template-columns="repeat(2, minmax(0, 1fr))"
            grid-gap="30px"
          >
            <SimpleCard2
              v-for="(v, i) in adime_notes_and_indicator_progress_and_tracker_schedule"
              :key="v.id + i"
              :title="v.title"
              :description="v.date"
              :status="v.status"
            />
          </c-box>
        </c-box>
        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Jadwal Follow Up Notes
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />
          <c-box
            display="grid"
            grid-template-columns="repeat(2, minmax(0, 1fr))"
            grid-gap="30px"
          >
            <SimpleCard2
              v-for="(v, i) in follow_up_notes_schedule"
              :key="v.id + i"
              :title="v.title"
              :description="v.date"
              :status="v.status"
            />
          </c-box>
        </c-box>
        <c-box>
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="#008C81"
          >
            Jadwal Progress & Tracker Feedback
          </BaseText>
          <BaseDivider margin="10px 0 30px 0" />
          <c-box
            display="grid"
            grid-template-columns="repeat(2, minmax(0, 1fr))"
            grid-gap="30px"
          >
            <SimpleCard2
              v-for="(v, i) in progress_and_tracker_feedback_schedule"
              :key="v.id + i"
              :title="v.title"
              :description="v.date"
              :status="v.status"
            />
          </c-box>
        </c-box>
      </c-box>

      <!--    <ModalNutritionists-->
      <!--      :is-open="isModalOpen"-->
      <!--      :fullname="getFullnameAndTitle(`${nutritionistModal.firstName || ''} ${nutritionistModal.lastName || ''}`.trim(), nutritionistModal.education)"-->
      <!--      :photo-url="nutritionistModal.photoUrl"-->
      <!--      :str-number="nutritionistModal.str"-->
      <!--      :languages="nutritionistModal.languages ? [-->
      <!--        nutritionistModal.languages-->
      <!--      ] : []"-->
      <!--      :rating="nutritionistModal.rating"-->
      <!--      :problem-handles="nutritionistModal.problemHandled"-->
      <!--      :client-age-handles="nutritionistModal.clientAgeHandled ? [-->
      <!--        nutritionistModal.clientAgeHandled,-->
      <!--      ] : []"-->
      <!--      :specializations="nutritionistModal.specialization"-->
      <!--      :educations="educationsParser(nutritionistModal.education)"-->
      <!--      :work-experiences="workExperiencesParser(nutritionistModal.workExperiences)"-->
      <!--      :service-hour="nutritionistModal.serviceHour"-->
      <!--      :chat-active-hours="chatActiveHoursParser(nutritionistModal.activeHour)"-->
      <!--      :service-times="serviceTimesParser(nutritionistModal.serviceTime)"-->
      <!--      @on-close="isModalOpen = false"-->
      <!--    />-->
    </c-box>

    <ModalConfirm
      :title="`Apakah anda yakin ingin mengubah data end date dari ${formatDateV2(program.endAt)} menjadi ${formatDateV2(endDate)}?`"
      image="https://ik.imagekit.io/dietela/pwa_webp/popup/apakah_yakin.png?updatedAt=1690890254662"
      :is-open="isOpenConfirmSetEndDate"
      :is-loading="isLoadingSetEndDate"
      submit-text="Simpan"
      @close="onCloseConfirm"
      @submit="onSubmitConfirm"
    />
    
    <ModalSuccess 
      title="Data end date berhasil diubah!"
      note=""
      :image="require('@/assets/paid.png')"
      :is-open="isSuccess"
      button-text="Oke"
      @close="onCloseSuccess"
    />
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
// import ModalNutritionists from '@/components/widgets/modal-nutritionists.vue'
import CardNutritionist from '@/components/widgets/card-nutritionist.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import SimpleCard from '@/components/widgets/simple-card.vue'
import SimpleCard2 from '@/components/widgets/simple-card-2.vue'
import { mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import { formatDateV2 } from '@/utils/format-date'
import modalNutritionistsMixin from '@/components/widgets/modal-nutritionists-mixin'
import moment from 'moment'
import ModalConfirm from '@/components/widgets/modal-confirm'
import ModalSuccess from '@/components/widgets/modal-success'

export default {
  name: 'ManagementClientPrograms',
  components: {
    SimpleCard2,
    BaseDivider,
    BaseText,
    CardNutritionist,
    // ModalNutritionists,
    BreadcrumbPath,
    SimpleCard,
    ModalConfirm,
    ModalSuccess,
  },
  mixins: [
    generalMixin,
    modalNutritionistsMixin,
  ],
  data() {
    return {
      meal_plan_schedule: [],
      adime_notes_and_indicator_progress_and_tracker_schedule: [],
      follow_up_notes_schedule: [],
      progress_and_tracker_feedback_schedule: [],
      program: {},
      user: {},
      nutritionist: {},
      universities: {},
      isModalOpen: false,
      isSetEndDate: false,
      openSetEndDate: false,
      endDate: null,
      minEndDate: null,
      isOpenConfirmSetEndDate: false,
      isLoadingSetEndDate: false,
      isSuccess: false,
      masks: {
        input: 'DD MMMM YYYY',
        data: 'YYYY-MM-DD',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
        timeAdjust: '00:00:00',
      },
      // nutritionistModal: {},
    }
  },
  computed: {
    LIST_MENU_DIET_DATA() {
      return [
        {
          label: 'Rencana Makan',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/mealplan_icon.webp?updatedAt=1688397449063',
          onClick: null,
          // onClick: { name: 'admin.clients-mealplan' },
        },
        {
          label: 'Catatan Evaluasi',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/note_icon.webp?updatedAt=1688397449187',
          onClick: { name: 'admin.clients-followup-notes' },
          onButtonClick: null,
        },
        {
          label: 'Pemantauan',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/tracker_icon.webp?updatedAt=1688397449189',
          onClick: { name: 'admin.clients-progress' },
          onButtonClick: null,
        },
        {
          label: 'Formulir Gizi',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/questionnaire_icon.webp?updatedAt=1688397449260',
          onClick: {
            name: 'admin.clients-quizionary',
          },
          onButtonClick: null,
        },
      ]
    },
    isAbleToChangeNutri() {
      let programEndDate = new Date(this.program.endAt).valueOf()
      let today = Date.now()
      return programEndDate > today
    },
    programId() {
      return this.$route.params.programId
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDateV2,
    ...mapActions({
      loadUniversities: 'general/loadUniversities',
      getNutritionistDetailProgram: 'clients/getNutritionistDetailProgram',
      getNutritionistDetailProgramNutritionist: 'clients/getNutritionistDetailProgramNutritionist',
      getNutritionistDetailProgramMealPlan: 'clients/getNutritionistDetailProgramMealPlan',
      getNutritionistDetailProgramAdime: 'clients/getNutritionistDetailProgramAdime',
      getNutritionistDetailProgramFollowUp: 'clients/getNutritionistDetailProgramFollowUp',
      getNutritionistDetailProgramProgressTracker: 'clients/getNutritionistDetailProgramProgressTracker',
      updateProgramEndDate: 'admClients/updateProgramEndDate',
    }),
    routerPush(path) {
      this.$router.push(path)
    },
    handleChangeNutritionist() {
      this.$router.push({ name: 'admin.clients-nutris' })
    },
    async init() {
      const programId = this.$route.params.programId

      try {
        const [
          resLoadUniversities,
          resGetNutritionistDetailProgram,
          resGetNutritionistDetailProgramMealPlan,
          resGetNutritionistDetailProgramAdime,
          resGetNutritionistDetailProgramFollowUp,
          resGetNutritionistDetailProgramProgressTracker,
        ] = await Promise.all([
          this.loadUniversities(),
          this.getNutritionistDetailProgram(programId),
          this.getNutritionistDetailProgramMealPlan(programId),
          this.getNutritionistDetailProgramAdime(programId),
          this.getNutritionistDetailProgramFollowUp(programId),
          this.getNutritionistDetailProgramProgressTracker(programId),
        ])

        this.program = resGetNutritionistDetailProgram.data.program
        this.user = resGetNutritionistDetailProgram.data.user
        this.nutritionist = resGetNutritionistDetailProgram.data.nutritionist
        this.universities = resLoadUniversities
        this.meal_plan_schedule = resGetNutritionistDetailProgramMealPlan.data
        this.adime_notes_and_indicator_progress_and_tracker_schedule = resGetNutritionistDetailProgramAdime.data
        this.follow_up_notes_schedule = resGetNutritionistDetailProgramFollowUp.data
        this.progress_and_tracker_feedback_schedule = resGetNutritionistDetailProgramProgressTracker.data
        this.minEndDate = moment(this.program.startAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
        this.endDate = moment(this.program.endAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
        this.isSetEndDate = resGetNutritionistDetailProgram.data.isSetEndDate || false

      } catch (e) {
        console.error(e)
      }
    },
    onOpenConfirm() {
      this.isOpenConfirmSetEndDate = true
    },
    onCloseConfirm() {
      if (!this.isLoadingSetEndDate) {
        this.isOpenConfirmSetEndDate = false
      }
    },
    onSubmitConfirm() {
      this.isLoadingSetEndDate = true
      this.updateProgramEndDate({
        programId: this.programId,
        endAt: moment(this.endDate).format('YYYY-MM-DD'),
      })
        .then(() => {
          this.openSetEndDate = false
          this.isSuccess = true
          this.init()
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err?.data?.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingSetEndDate = false
          this.onCloseConfirm()
        })
    },
    onCloseSuccess() {
      this.isSuccess = false
    },
  },
}
</script>
